div.table {
	display: table;
    border-collapse: collapse;
}

div.table > div {
	display: table-row-group;
}

div.table > div.table-head {
	font-weight: bold;
}

div.table > div > a, div.table > div > div {
	display: table-row;
	color: black;
}

div.table > div > a > span, div.table > div > a > div,
div.table > div > div > span, div.table > div > div > div {
	display: table-cell;
	overflow: hidden;
	padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

div.table > div > div.table-header > span {
    cursor: pointer;
}

div.table > div.table-head > div.table-filter-header {
    border-top: 1px solid #dee2e6;
}

div.table > div.table-head > div.table-filter-header > span {
    padding-right: .1rem;
}

div.table > div > div.table-header > span.actions {
    cursor: default;
    text-align: right;
}

div.table > div > a:hover {
	text-decoration: none;
	color: black;
}

div.table > div.table-body > a {
    overflow-wrap: break-word;
}

div.table > div.table-body > a:hover {
    background-color: rgba(0, 0, 0, .15) !important;
}

div.table > div.table-body > a.locked:hover {
    background-color: rgba(230, 0, 50, .15) !important;
}

div.table > div.table-body > a:nth-child(even),
div.table > div.table-body > div:nth-child(even) {
    background: #fff;
}

div.table > div.table-body > a.locked:nth-child(even),
div.table > div.table-body > div.locked:nth-child(even) {
    background: rgba(230, 0, 50, .25);
}

div.table > div.table-body > a:nth-child(odd),
div.table > div.table-body > div:nth-child(odd) {
    background: #eee;
}

div.table > div.table-body > a.locked:nth-child(odd),
div.table > div.table-body > div.locked:nth-child(odd) {
    background: rgba(230, 0, 50, .4);
}

input.has-warning {
    border-color: #f0ad4e;
}

#root {
    margin-bottom: 50px;
}

div.password-options ul {
    margin-top: .5rem;
    padding-left: 22px;
    margin-bottom: 0;
    list-style: none;
}

div.password-options-show ul {
    padding-left: 0;
}

div.password-options li {
    margin-bottom: .25rem;
}

div.password-options label {
    margin-left: .4rem;
    user-select: none;
}

div.password-options i.fas {
    margin-right: .5rem;
}

.alert-sticky {
    position: fixed;
    top: 2%;
    left: 38%;
}

.centered {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.navbar {
    user-select: none;
}

textarea#tfa-key-display {
    resize: none;
    width: inherit;
    font-family: monospace;
}

.ellipsis-overflowing-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 0;
}

/* Taken from https://loading.io/css/ */
.lds-dual-ring {
    display: inline-block;
    padding-top: 4px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 24px;
    height: 24px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
